import PanelService from "@/api/panelService";

const state = () => ({
  user: {
    id: null,
    email: "",
    name: "",
    avatar: "",
    balance: 0,
    telegram: "",
    skype: "",
    news: 0,
    tickets: 0,
    rid: 0,
    ref_code: "",
    ban_reason: "",
    confirm: true,
    roles: [],
    alert: null,
  },
  isGuestMode: false,
});

const getters = {
  getUser: (state) => {
    return state.user;
  },
  isGuestMode: (state) => {
    return state.isGuestMode;
  },
};

const mutations = {
  setUserStore(state, payload) {
    if (payload) {
      state.user = payload;
    }
  },
  setGuestMode(state, payload) {
    state.isGuestMode = payload;
  },
};

const actions = {
  setUser({ commit }) {
    PanelService.getUser().then((result) => {
      commit("setUserStore", result);
    });
  },
  getGuestMode({ state, commit, dispatch }) {
    if (localStorage.getItem("guestToken") && !state.isGuestMode) {
      commit("setGuestMode", true);
    }
    if (state.isGuestMode && !localStorage.getItem("guestToken")) {
      commit("setGuestMode", false);
      dispatch("setUser");
    }
  },
  logOutGuestMode({ commit }) {
    localStorage.removeItem("guestToken");
    commit("setGuestMode", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
