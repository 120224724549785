import { render, staticRenderFns } from "./ProfileAvatar.vue?vue&type=template&id=12180978&scoped=true&"
import script from "./ProfileAvatar.vue?vue&type=script&lang=js&"
export * from "./ProfileAvatar.vue?vue&type=script&lang=js&"
import style0 from "./ProfileAvatar.vue?vue&type=style&index=0&id=12180978&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12180978",
  null
  
)

export default component.exports