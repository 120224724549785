import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import statistics from "./modules/statistics";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    statistics,
  },
});
