<template>
  <multiselect
    v-model="language"
    :options="languageSelect"
    class="small-select"
    :class="{ 'select-language': leftMenu, 'simple-select': simple }"
    placeholder=""
    :allowEmpty="false"
    :loading="isLoading"
    :searchable="false"
    :show-labels="false"
    @input="changeLanguage"
  >
    <template slot="singleLabel" slot-scope="props">
      <template v-if="!simple">
        {{ props.option[0].toUpperCase() + props.option[1] }}
      </template>
    </template>
    <template slot="option" slot-scope="props">
      <template v-if="!simple">
        {{ props.option[0].toUpperCase() + props.option[1] }}
      </template>
    </template>
  </multiselect>
</template>
<script>
 
export default {
  name: "selectLanguage",
  props: {
    leftMenu: {
      type: Boolean,
      default: false,
    },
    simple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      language: localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "en",
      languageSelect: ["ru", "en", "uk", "es"],
      isLoading: false,
    };
  },
  beforeCreate() {
    const savedLang = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    if (savedLang) {
      this.$i18n.locale = savedLang;
      this.language = savedLang;
    } else {
      this.$i18n.locale = navigator.language || navigator.userLanguage;
    }
    this.isLoading = true;
  },
  mounted() {
    this.isLoading = false;
  },
  watch: {
    "$i18n.locale": function () {
      this.language = this.$i18n.locale;
    },
  },
  methods: {
    setFLag(val) {
      return val === "en" ? "gb" : val === "uk" ? "ua" : val;
    },
    changeLanguage(event) {
      const newLang = event;
      localStorage.setItem("lang", newLang);
      this.$i18n.locale = newLang;
    },
  },
};
</script>
<style lang="scss" scoped>
.multiselect {
  &.small-select {
    min-width: 80px;
  }
  &.select-language {
    min-width: 80px;
    ::v-deep {
      .multiselect__tags {
        background: #f7f7f9;
        border: 1px solid transparent;
        border-radius: 5px;
        padding: 0 17px 0 0;
        font-size: 16px;
        height: 45px;
        outline: none !important;
        box-shadow: none !important;
      }
    }
  }
  ::v-deep {
    .multiselect__tags {
      background: #fff;
    }
    .multiselect__single {
      cursor: pointer;
      background: transparent;
    }
  }
  &.simple-select {
    width: 30px;
    min-width: 30px;
    height: 20px;
    padding: 0;
    min-height: 20px;
    ::v-deep {
      .multiselect__select {
        padding: 0;
        width: 30px;
        height: 20px;
        min-height: 20px;
        &:before {
          display: none;
        }
      }
      .multiselect__tags {
        min-height: 20px;
        background: none;
        padding: 0;
        height: 20px;
        width: 30px;
      }
      .multiselect__element {
        padding: 0;
      }
      .multiselect__option {
        padding: 0;
        min-height: 20px;
        line-height: 1;
        background: none;
      }
      .multiselect__single {
        padding: 0;
        background: none;
        margin: 0;
      }
      .multiselect__option--selected.multiselect__option--highlight {
        background: none;
      }
      .multiselect__content-wrapper {
        padding: 0;
        background: #fff;
        border: 0;
        border-radius: 3px;
        overflow: hidden;
        margin-top: 5px;
      }
      .multiselect__content {
        background: none;
        border: none;
        border-radius: 0;
      }
    }
  }
}
</style>
