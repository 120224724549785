import AxiosService from "@/services/AxiosService";
class PanelService {
  async getUser() {
    return await AxiosService.get(`/user`)
      .then((response) => {
        return response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getFeeds() {
    return await AxiosService.get(`/feeds`)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getFinancesHistory() {
    return AxiosService.get("/finances/history")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  withdrawFromWallet(payload) {
    return AxiosService.post("/finances/withdraw", payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  updateLanguage(lang) {
    return AxiosService.patch("/user/language", lang)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async updateWallets(payload) {
    return await AxiosService.post("/user/wallets", payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  getWallets() {
    return AxiosService.get("/user/wallets")
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async updateUserData(payload) {
    return await AxiosService.post("/user/profile", payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async updatePassword(payload) {
    return await AxiosService.post("/user/change-password", payload)
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getStatistics(payload) {
    return await AxiosService.get("/statistics", {params: payload})
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getBrowsers() {
    return await AxiosService.get(`/browsers`)
      .then((response) => {
        return response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getCountries() {
    return await AxiosService.get(`/countries`)
      .then((response) => {
        return response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getOs() {
    return await AxiosService.get(`/os`)
      .then((response) => {
        return response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getClickSlog(payload) {
    return await AxiosService.get(`/clicklog-token`,{params: payload})
      .then((response) => {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
export default new PanelService();
