import panelService from "@/api/panelService";

const state = () => ({
  browsers: [],
  countries: [],
  os: [],
  feeds: [],
});

const getters = {
  getBrowsers: (state) => {
    return state.browsers;
  },
  getCountries: (state) => {
    return state.countries;
  },
  getOS: (state) => {
    return state.os;
  },
  getFeeds: (state) => {
    return state.feeds;
  },
};

const mutations = {
  setBrowsers(state, payload) {
    if (payload) {
      state.browsers = payload;
    }
  },
  setCountries(state, payload) {
    if (payload) {
      state.countries = payload;
      state.countries.unshift({
        name_ru: "Все страны",
        name_en: "All countries",
      });
    }
  },
  setFeeds(state, payload) {
    if (payload) {
      state.feeds = payload.data;
      state.feeds.unshift({
        id: 0,
        name: "All feeds",
      });
    }
  },
  setOS(state, payload) {
    if (payload) {
      state.os = payload;
    }
  },
};

const actions = {
  handleBrowsers({commit}) {
    panelService.getBrowsers().then((result) => {
      commit("setBrowsers", result);
    });
  },
  handleCountries({commit}) {
    panelService.getCountries().then((result) => {
      commit("setCountries", result);
    });
  },
  handleOS({commit}) {
    panelService.getOs().then((result) => {
      commit("setOS", result);
    });
  },
  handleFeeds({commit}) {
    panelService.getFeeds().then((result) => {
      commit("setFeeds", result);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
