<template>
  <div>
    <div class="single-user">
      <div class="user">
        <div class="user__prev" :class="{ left: left }">
          <span v-if="isGuestMode" class="user__avatar-spy">
            <svg v-svg symbol="spy" size="0 0 21 21" role="info"></svg>
          </span>
          <span v-else class="user__avatar align-center">{{ avatarName }}</span>
          <span class="user__info" :class="{ full: fullName }">
            <span class="user__nick">{{ user.name }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    fullName: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      isGuestMode: "user/isGuestMode",
    }),
    avatarName() {
      return this.user.name
        ?.split(" ")
        .map((n) => n[0])
        .join("")
        .toUpperCase();
    },
  },
};
</script>

<style scoped lang="scss">
.user {
  width: 195px;
  &__avatar {
    line-height: 1;
  }
  &__info {
    width: calc(100% - 60px);
    &.full {
      width: 100%;
    }
  }
  &__nick {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 1300px) {
  .user {
    width: auto;
    &__prev {
      justify-content: end;
      &.left {
        justify-content: start;
      }
    }
  }
}
</style>
