<template>
  <div>
    <slot />
  </div>
</template>

<script>
import "@/assets/css/vendor/bootstrap.min.css";
import "@/assets/css/main.scss";
import { setLangHeader } from "@/services/AxiosService";

export default {
  name: "DefaultLayout",
  watch: {
    "$i18n.locale": function () {
      setLangHeader(localStorage.getItem("lang"));
    },
  },
};
</script>