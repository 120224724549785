export const helper = {
  copyCode(ref) {
    let testingCodeToCopy = document.getElementById(ref);
    console.log(testingCodeToCopy);
    testingCodeToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
    testingCodeToCopy.select();

    try {
      const successful = document.execCommand("copy");
      let msg = successful ? "successful" : "unsuccessful";
      console.log(msg);
    } catch (err) {
      alert("Oops, unable to copy");
    }
  },
};
